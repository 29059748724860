<template>
	<div class="enter-box">
        <div class="enter-bg"></div>
        <div class="enter-content">
            <div class="left">
                <p>欢迎访问<br/>恒安康泰集团</p>
                <p>客服电话：4001 089 289</p>
            </div>
            <div class="right">
                <p>
                    <span class="p01" @click="$router.push({name:'index'})">点击进入</span> <br>
                    <span class="p02">
                        恒安康泰提供全项目管理服务，立足医疗，开发，实业，教育，提供企项一体化的服务
                    </span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default { 
	name: '',
	data () {
		return{

        }
	},
	props: {
	},
	methods:{		
		
	},
}
</script>

<style scoped lang="less">
// pc端样式
@media screen and (min-width:750px){
    .enter-box{
        width: 100%;
        height: 100vh; 
        overflow: hidden;
        position: relative;

        .enter-bg{
            width: 100%;
            transform: scale(2.1);
            height: 200vh; 
            position: absolute;
            background-image: linear-gradient(55deg,#33c0b0,#C181AD,#4f95a1,#2980B9,#ad4be6,#c23f7e);  
            background-size: 400%;
            animation: bganimation 30s ease-in-out infinite;
            animation-direction:alternate;

        }
    }
    @keyframes bganimation {
        0%{
            background-image:linear-gradient(155deg);
            background-position: 0% 50%;
        
        }
        100%{
            background-image:linear-gradient(115deg);
            background-position: 100% 50%;;
        } 
    }
    .enter-content{
        width: 1200Px;
        background:  url(../../assets/img/enter/map.png) no-repeat center;
        height: 100%;
        margin: auto;
        color: #fff;
        display: flex;        
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 10;
        .left{
            width: 600Px;
            height: 900Px;
            font-size: 55Px;
            :first-child{
                letter-spacing:5Px;
                padding: 370Px 0 340Px;
            }
            :last-child{
                font-size: 16Px;
            }
        }
        .right{
            display: flex;
            width: 770Px;
            height: 200Px;
            align-items: center;
            text-align: center;
            padding-left: 185Px;
            flex-wrap: wrap;
            p{
                font-size: 23Px;
                letter-spacing: 2Px;
                width: 100%;
                font-weight: 700;
                .p01{
                    cursor: pointer;
                    line-height: 41Px;
                    &:hover{
                        border-bottom:2Px solid #fff;
                    }
                }
                .p02{
                    font-weight: normal;
                    letter-spacing: 1Px;
                    font-size: 14Px;
                }
            }
        }
    }
}

// 移动端样式
@media screen and (max-width:750px){
    .enter-box{
        width: 100%;
        position: relative;
        overflow: hidden; 
        .enter-bg{
            width: 100%;
            position: absolute;
            background-image: linear-gradient(55deg,#33c0b0,#C181AD,#4f95a1,#2980B9,#ad4be6,#c23f7e);  
            height: 150vh; 
            background-size: 400%;
            animation: bganimation 30s ease-in-out infinite;
            animation-direction:alternate;
        }
    }
    @keyframes bganimation {
        0%{
            background-image:linear-gradient(155deg);
            background-position: 0% 50%;
        
        }
        100%{
            background-image:linear-gradient(115deg);
            background-position: 100% 50%;;
        } 
    }
    .enter-content{
        width: 100%;
        background:  url(../../assets/img/enter/map.png) no-repeat center / 332%;
        margin: auto;
        height: 100vh; 
        color: #fff;
        position: relative;
        z-index: 10;
        .left{
            width: 100%;
            font-size: 32px;
            :first-child{
                padding: 65% 0 81% 30px;
                letter-spacing: 1px;
                font-weight: 700;
            }
            :last-child{
                font-size: 13px;
                cursor: pointer;
                font-weight: 500;
                padding-left: 30px;
                position: absolute;
                bottom: 19px;
            }
        }
        .right{
            height: 200px;
            margin: -285px 0 0 30px;
            p{
                font-size: 20px;
                width: 100%;
                font-weight: 700;
                .p01{
                    line-height: 30px;
                }
                .p02{
                    font-weight: normal;
                    font-size: 12px;
                    opacity: 0.8;
                }
            }
        }
    }

}
</style>
